/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { I18N_REPORT_NS } from '../../../i18n'
import { css } from '@emotion/css'
import { renderValue } from './ReportHeader'
import { renderQuarter } from '..'

export const MetricsTable = ({ reports }) => {
	const { t } = useTranslation([I18N_REPORT_NS])

	const rowTitles = ['mrr', 'revenue', 'ebitda']

	return (
		<table
			className={css({
				minWidth: 420,
				width: '100%'
			})}
		>
			<thead>
				<tr
					className={css({
						backgroundColor: 'var(--violet-bg)',
						height: 56
					})}
				>
					<th
						className={css({
							'&:first-child': {
								borderRadius: '10px 0 0 10px'
							}
						})}
					></th>
					{reports?.map(report => (
						<th
							key={report?.id}
							className={css({
								'&:last-child': {
									borderRadius: '0 10px 10px 0'
								}
							})}
						>
							<span className={css({ textAlign: 'center' })}>
								{report?.quarter ? renderQuarter(report?.quarter) : '-'}
							</span>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rowTitles.map(key => (
					<tr key={key}>
						<td className={css({ fontWeight: 600, width: 200 })}>{t(key)}</td>
						{reports?.map(report => (
							<td
								key={report?.[key]}
								className={css({
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									borderBottom: '1px solid var(--violet-bg)'
								})}
							>
								<div
									className={css({
										fontSize: 12,
										textOverflow: 'ellipsis',
										textAlign: 'center',
										padding: '24px 8px',
										width: '100%'
									})}
									title={report?.[key]}
								>
									{report?.[key] || report?.[key] === 0 ? renderValue(report[key]) : '-'}
								</div>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}
