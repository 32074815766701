/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { I18N_REPORT_NS } from '../../../i18n'
import { Space, Typography } from 'antd'
import { css } from '@emotion/css'

import QuarterColumn from '../../../components/charts/quarterColumn'
import { renderValue } from './ReportHeader'
import { renderQuarter } from '..'

const vertSpaceProps = { direction: 'vertical', style: { width: '100%' } }
const getColorPer = per => (per?.[0] === '-' ? 'var(--red-bright)' : 'var(--green-bright)')

export const Revenue = ({ report, reports, revenue_per, prev_quarter }) => {
	const { t } = useTranslation([I18N_REPORT_NS])
	const { quarter, revenue } = report
	const revenue_per_quarter = reports
		?.sort((a, b) => new Date(a.quarter) - new Date(b.quarter))
		?.reduce((acc, { revenue, quarter }) => [...acc, { valuation: revenue, label: renderQuarter(quarter) }], [])

	return (
		<Space {...vertSpaceProps} size={0}>
			<Typography.Text strong className={css({ fontSize: 14, lineHeight: 1 })}>
				{t('revenue_title')}
			</Typography.Text>
			<Space
				size={16}
				css={{
					'.ant-space-item': {
						width: 'fit-content'
					}
				}}
			>
				<Typography.Text className={css({ whiteSpace: 'nowrap', fontSize: 12 })}>
					{`${quarter ? renderQuarter(quarter) : '-'}: $${renderValue(revenue)}`}
				</Typography.Text>

				<Typography.Text
					className={css({ color: getColorPer(revenue_per), whiteSpace: 'nowrap', fontSize: 12 })}
				>
					{(revenue_per || revenue_per === 0) && `${revenue_per > 0 ? '+' : ''}${revenue_per}% `}
					{prev_quarter !== '-' ? `${t('compared_to', { quarter: prev_quarter })}` : ''}
				</Typography.Text>
			</Space>
			<Space direction='vertical' css={{ marginTop: 32, width: '100%', overflowX: 'auto' }}>
				{revenue_per_quarter?.length ? <QuarterColumn data={revenue_per_quarter} title='Revenue' /> : null}
			</Space>
		</Space>
	)
}
